<template>
  <el-dialog
    title="长宽编辑"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-form
      :model="form"
      ref="form"
      label-width="100px"
      class="multiple-ruleForm"
      label-position="top"
      size="small"
      :disabled="submitting"
    >
      <el-row :gutter="10">
        <el-col
          :sm="24"
          :md="24"
          v-for="(item, index) in form.items"
          :key="item.key"
        >
          <el-card
            class="box-card"
            shadow="never"
            :body-style="{
              padding: ' 0 10px',
            }"
          >
            <div slot="header" class="clearfix">
              条目{{ index + 1 }}
              <el-button
                class="text-danger"
                style="float: right; padding: 3px 0"
                type="text"
                @click="onDeleteRow(index)"
                :disabled="submitting || form.items.length <= 1"
                >删除</el-button
              >
            </div>

            <el-row :gutter="10">
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="长度"
                  :prop="'items.' + index + '.lengthMm'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入长度',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model="item.lengthMm"
                    type="number"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    @blur="handleExistCheck(item, index)"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label-position="left"
                  label="宽度"
                  :prop="'items.' + index + '.widthMm'"
                  :rules="[
                    {
                      required: true,
                      message: '请输入宽度',
                      trigger: 'blur',
                    },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.widthMm"
                    type="number"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    @blur="handleExistCheck(item, index)"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label="次序(数值越大越靠前)"
                  :prop="'items.' + index + '.seq'"
                  :rules="[
                    { required: true, message: '请输入次序', trigger: 'blur' },
                  ]"
                >
                  <el-input
                    :disabled="submitting"
                    v-model.trim="item.seq"
                    clearable
                    :ref="`input${index}`"
                    placeholder="请输入"
                    type="number"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6">
                <el-form-item
                  label="是否已存在"
                  :prop="'items.' + index + '.isExists'"
                >
                  <div v-if="item.isChecking">
                    <i class="el-icon-loading"></i>
                    查重中...
                  </div>
                  <div v-else>
                    <span class="text-danger" v-if="item.isExists"
                      >该组合已存在</span
                    >
                    <span class="text-success" v-else>可添加</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card></el-col
        >
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleReset" :disabled="submitting">重 置</el-button>
      <el-button @click="addRow" :disabled="submitting">新增一行</el-button>
      <el-button
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >{{ submitting ? "提交中..." : "提交创建" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { createUniqueString } from "@/utils/common";
import { PostLengthWidth, GetLengthWidthExistsCheck } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitting: false,
      focusIndex: 0,
      dialogVisible: false,
      checkingExists: false,
      form: {
        items: [
          {
            lengthMm: "",
            widthMm: "",
            isExists: false,
            isChecking: false,
            seq: 1,
            key: this.createUniqueString(),
          },
        ],
      },
    };
  },
  watch: {
    show: {
      handler(value) {
        this.dialogVisible = value;
      },
      immediate: true,
    },
  },
  methods: {
    createUniqueString,
    async handleExistCheck(item, index) {
      if (isEmprty(item.lengthMm) || isEmprty(item.widthMm)) {
        this.form.items[index].isExists = false;
        return;
      }
      try {
        item.isChecking = true;
        let { data } = await GetLengthWidthExistsCheck({
          lengthMm: item.lengthMm,
          widthMm: item.widthMm,
        });
        this.form.items[index].isExists = data;
      } finally {
        item.isChecking = false;
      }
    },
    buildFormData() {
      let datas = [];
      datas = this.form.items
        .filter((item) => item.isExists == false)
        .map((item) => {
          delete item.key;
          delete item.isExists;
          delete item.isChecking;
          return item;
        });
      return datas;
    },
    validForm() {
      return new Promise((resolve, reject) => {
        let res = this.form.items.every((item) => item.isExists == false);
        if (res) {
          resolve();
        } else {
          this.$confirm(
            "提交的表单中存在重复的组合，是否需要自动去除并提交?",
            "重复提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              resolve();
              this.$message.success("表单已重置");
            })
            .catch(() => {
              this.$message.info("已取消");
              reject();
            });
        }
      });
    },
    submitForm(formName) {
      let flag = this.form.items.some((item) => item.isChecking == true);
      if (flag) {
        this.$message.info("请等待查重完成");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.validForm().then(() => {
            this.submitting = true;
            PostLengthWidth(this.buildFormData())
              .then(() => {
                this.submitting = false;
                this.$message.success("新增成功");
                this.$emit("success");
                this.initForm();
              })
              .catch((err) => {
                this.submitting = false;
                ShowApiError("新增失败", err);
              });
          });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    initForm() {
      this.form = {
        items: [
          {
            lengthMm: "",
            widthMm: "",
            isExists: false,
            isChecking: false,
            seq: 1,
            key: this.createUniqueString(),
          },
        ],
      };
    },
    addRow(inputObj = "") {
      this.form.items.push({
        lengthMm: "",
        widthMm: "",
        isExists: false,
        isChecking: false,
        seq: this.form.items.length + 1,
        key: this.createUniqueString(),
      });
      this.$nextTick(() => {
        if (this.$refs[inputObj]) {
          this.$refs[inputObj][0].focus();
        }
      });
    },
    onDeleteRow(index) {
      if (this.form.items.length > 1) {
        this.form.items.splice(index, 1);
      }
    },
    handleReset() {
      this.$confirm("操作不可撤回，确定要重置表单吗?", "重置提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.initForm();
          this.$message.success("表单已重置");
        })
        .catch(() => {});
    },
    onDialogClose() {
      this.dialogVisible = false;
      this.$emit("close", this.dialogVisible);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-img {
  width: 100px;
  height: 100px;
}
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
.box-card {
  margin-bottom: 10px;
}
.multiple-ruleForm {
  padding: 0 10px;
}
</style>
